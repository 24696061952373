<template>
 <v-row
    align="center"
    style="height: 750px;"
    justify="center"
  >
    <v-col cols="12" md="5" align-self="center" class="mt-sm-5,mt-xs-5,ma-5">
      <v-data-table
        :headers="headers"
        :items="items"
        items-per-page="12"
        hide-default-footer
        show-select
        v-model="selected"
        item-value="docId"
        return-object
      >
      </v-data-table>
      
    </v-col>
    
    <v-col cols="12" md="5" align-self="center" class="ma-5">
      <LineChart :chartData="reportData" :options="data.reportPage.graph.options" ref="linechart" />
      <v-select
        label="表示項目"
        :items="data.viewItem"
        item-title="title"
        item-value="value"
        v-model="data.viewItemModel"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { data } from '../../composables/useData';
import { method } from '../../composables/useMethod';
import { BarChart,LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { computed,ref,watch,onMounted  } from 'vue';
import dayjs from 'dayjs';
Chart.register(...registerables);
const linechart = ref(null);
onMounted(() => {

  if (linechart.value) {
    const chartInstance = linechart.value.chartInstance;
    // console.log('Chart Data:', chartInstance.data);
  }
});

const selected = ref([]);
const headers:any =ref( [
      {
        title: '日付',
        align: 'start',
        sortable: true,
        key: 'date',
      },
      { title: 'ユーザー数', align:'end',key:'activeUsers',sortable: true, },
      { title: 'ページビュー数', align:'end',key:'screenPageViews',sortable: true, },
    ]);
const items = ref( method.report.sum());

function updateItems(){
  items.value = method.report.sum();
}
watch(() => data.loginUser.activeReport, () => {
  updateItems();
});

const reportData = computed(() => {
  if (linechart.value) {
    const chartInstance = linechart.value.chartInstance;
    chartInstance.data.datasets = [];
  }

  console.log('selected.value', method.report.graphData('dateData'));

  const chartData = method.report.graphData('dateData').filter((item) => {
    return selected.value.find((reportData) => reportData.docId === item.docId);
  });

  console.log('chartData', chartData);

  if (!chartData[0]) {
    console.log('false');
    return {
      labels: [],
      datasets: [],
    };
  } else {
    console.log('true', data.viewItemModel);

    const datasets = chartData.map((item) => {
      console.log('item', item);

      const selectedItem = item.datasets.data.map((dataItem) => {
        console.log('datasets filter dataItem', dataItem);
        return dataItem[data.viewItemModel as string];
      });

      console.log('selectedItem', selectedItem);

      return {
        label: item.datasets.label,
        data: selectedItem,
        backgroundColor: item.datasets.backgroundColor,
        fill: item.datasets.fill,
      };
    });

    console.log('datasets', datasets);
    return {
      labels: data.reportPage.graph.dateLabels,
      datasets: datasets,
    };
  }
});

</script>

<template>
  <BarChart :chartData="sourceReportData" :options="chartOptions" />
</template>

<script setup lang="ts">
import { data } from '../../composables/useData';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { computed } from 'vue';
import dayjs from 'dayjs';
Chart.register(...registerables);

const sourceReportData = computed(() => {

  const report = data.loginUser.viewReports[data.loginUser.activeReport];
  const sourceData = report.sourceData;

  const filteredSourceData = sourceData.filter(item => item.source !== 'google' && item.source !== 'yahoo' && item.source !== 'bing');

  const labels = filteredSourceData.map(item => item.source);
  const activeUsers = filteredSourceData.map(item => item.activeUsers);
  const pageViews = filteredSourceData.map(item => item.pageViews);

  return {
    labels,
    datasets: [
      {
        label: 'アクティブユーザー数',
        data: activeUsers,
        backgroundColor: '#36A2EB',
      },
      {
        label: 'ページビュー数',
        data: pageViews,
        backgroundColor: '#FF6384',
      }
    ]
  };
});

const chartOptions = {
  indexAxis: 'y', // グラフを水平にするためのオプション
};
</script>

import { reactive,Component,markRaw } from 'vue';
// import PageViews from '../views/ReportComponents/PageViews.vue';
// import UserCounts from '../views/ReportComponents/UserCounts.vue';
// import DeviceCounts from '../views/ReportComponents/DeviceCounts.vue';
// import SearchEngineCounts from '../views/ReportComponents/SearchEngineCounts.vue';


export const data: Data = reactive({
    list:{
      open:false,
    },
    dialog:{
      endUserAnalytics:false,
      endUserMailText:false,
      analyticsPreference:false,
    },
    disabled: false,
    naviDrawer: true,
    overlay:false,
    loginUser: {
      first: true,
      name: '',
      email: '',
      password: '',
      uid: '',
      // activeReportId: '',
      reportList:[
        {
          title: 'レポート表紙',
          componentName: 'TopView'
        },
        {
          title: '日別アクセス数',
          componentName: 'DayCounts'
        },
        {
          title: 'デバイス別数',
          componentName: 'DeviceCounts'
        },
        {
          title: '検索エンジン別数',
          componentName: 'SearchEngineCounts'
        },
        {
          title: 'ページ別数',
          componentName: 'PageData'
        },
        {
          title: '地域別数',
          componentName: 'RegionData'
        },
        {
          title: 'アクセス元別数',
          componentName: 'SourceData'
        }
      ],
      reports:{
        to:'/ga4ReportPage',
        properties:[],
        selectProperties:[],
        getAccountName:'',
        accounts:[{
          parent:'',
        }],
      },
      activeReport:0,
      activeReportId:0,
      activeComponentIndex:0,
      viewReports:[],
      viewReportSelected:[],
      activeReportList:{
        parent:'',
      },
      activeReportData:{},
      endUser:{
        name: '',
        email: '',
        password: '',
        uid: '',
        mailText:{
          subject: 'HPレポート作成のお知らせ',
          firstHalf: '先月のアクセス解析レポートを作成しました。ご確認ください。',
          secondHalf: 'ご不明点があればお気軽にお問い合わせください。\nよろしくお願いいたします。',
          signature: '',
        },
        list:[] as EndUser[],
        useProperties:[], 
      },
      googleTokens:false,
      options:{},
    },
    reportPage:{
      graph:{
        options:{
          responsive:true,
          maintainAspectRatio: false,
          scales:{
            y:{
              beginAtZero:true,
              min:0,
            },
            x:{
              ticks:{
                font:{
                  size:9,
                },
              }
            },
            },
          },
        dateLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        },
      colorList : [
        'rgba(255, 99, 132, 0.2)',  // 半透明の赤色
        'rgba(54, 162, 235, 0.2)',  // 半透明の青色
        'rgba(255, 206, 86, 0.2)',  // 半透明の黄色
        'rgba(75, 192, 192, 0.2)',  // 半透明の緑色
        'rgba(153, 102, 255, 0.2)', // 半透明の紫色
        'rgba(255, 159, 64, 0.2)',  // 半透明のオレンジ色
        'rgba(255, 99, 132, 0.2)',  // 半透明の赤色（重複）
        'rgba(54, 162, 235, 0.2)',  // 半透明の青色（重複）
        'rgba(255, 206, 86, 0.2)',  // 半透明の黄色（重複）
        'rgba(75, 192, 192, 0.2)',  // 半透明の緑色（重複）
        'rgba(153, 102, 255, 0.2)', // 半透明の紫色（重複）
        'rgba(255, 159, 64, 0.2)'   // 半透明のオレンジ色（重複）
        // 必要な色を追加
      ],
      
        
    },
    active:{
      accountIndex: null,
      activeMonth: '',
    },
    loginForm: {
      email: '',
      password: '',
    },
    google:{
      dialog:false,
    },
    viewItem:[
      {
        title:'ユーザー数',
        value:'activeUsers'
      },
      {
      title:'ページビュー',
      value:'screenPageViews'
      },
    ],
    viewItemModel:'activeUsers',
  });
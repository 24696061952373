import { initializeApp } from 'firebase/app'
import { getFirestore, serverTimestamp,DocumentData,doc,getDoc,setDoc,getDocs,addDoc,deleteDoc,collection,updateDoc,query,where,writeBatch,orderBy,limit } from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth'
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import dayjs from 'dayjs'

const firebaseApp = initializeApp(
{
    apiKey: "AIzaSyBw4kfm7QTRuwyE08Gu6wvyB0wqI4eL1rU",
    authDomain: "ga4report-394213.firebaseapp.com",
    projectId: "ga4report-394213",
    storageBucket: "ga4report-394213.appspot.com",
    messagingSenderId: "965983654524",
    appId: "1:965983654524:web:9eb2b63245095b238c6ff9"
    }
  )
  
  export const app = firebaseApp;
  console.log('app',app)
  // ドメインがlocalhostの場合は、デバッグトークンを有効にする
  // localhostかどうかをチェック
  if (location.hostname === "localhost") {
      console.log('localhost');
      // インデックスシグネチャを使用してプロパティを設定
      (window as any)['FIREBASE_APPCHECK_DEBUG_TOKEN'] = true;
    }    
  
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LcRspgcAAAAANqqpiNxqJzwaCYavrzOMsCxpC8c'),
    isTokenAutoRefreshEnabled: true
  });

  export const token = appCheck;
    const auth = getAuth();

    const firestoreDB = getFirestore(firebaseApp);
    const collections = {
        customer : collection(firestoreDB, 'customers'),
        customerSecret : collection(firestoreDB, 'customerSecrets'),
        analyticsDatas :collection(firestoreDB, 'analyticsDatas'),
        endUsers: collection(firestoreDB, 'endUsers'),
    }

  export const firebase = ({
    async userLogin(mail:string,password:string){
      try{
        return await signInWithEmailAndPassword(auth, mail, password)
        .then( userCredential => {
           console.log('ログイン成功',userCredential)
           return userCredential;
        })
      }catch(e){
        alert('ログイン出来ませんでした。メールアドレスとパスワードをご確認下さい')
      }
      },
      async userLogout(){
        return await signOut(auth).then( () => {
           return true;
        }).catch( e => {
          console.log('ログアウトエラー',e)
          return false;
        })
      },
      async userRegister(mail:string,password:string){
        return await createUserWithEmailAndPassword(auth, mail, password).then( userCredential => {
           console.log('登録成功',userCredential);
            return userCredential;
        },async e => {
          console.log('登録エラー',e.code)
          if(e.code === 'auth/email-already-in-use'){
            const result =  await this.userLogin(mail,password)
            return result;
          }
        }
        )
      },
      async resetPassword(email:string) {
        try {
          await sendPasswordResetEmail(auth, email);
          console.log('パスワードリセットメールを送信しました');
          alert('パスワードリセットメールを送信しました');
        } catch (e) {
          console.log('パスワードリセットエラー', e);
          alert('パスワードリセットエラー');
        }
      },
      firestore:{
        save: {
            async customer(loginUser:LoginUser){
                const docRef = doc(collections.customer,loginUser.uid);
                await setDoc(docRef,loginUser,{merge:true});
                return true;
            },
            async analyticsProperties(loginUser:LoginUser){
              const docRef = doc(collections.customer,loginUser.uid);
              console.log('analyticsProperties',loginUser)
              await setDoc(docRef,loginUser,{merge:true});
              return true;
            },
            async ga4Datas(datas:any){
              console.log('firestore save ga4Datas',datas)
              const batch = writeBatch(firestoreDB);
              datas.forEach(
                (setDatas:any) => {
                  const docRef = doc(collections.analyticsDatas);
                  const docId = docRef.id;
                  setDatas.docId = docId;
                console.log('ga4Datas setDatas',setDatas)
                batch.set(docRef, setDatas);
          
              });
              await batch.commit();
            },
            async endUser(endUser:EndUser){
              const docRef = doc(collections.endUsers,endUser.uid);
              await setDoc(docRef,endUser,{merge:true});
              return true;
            }
        },
        load:{
          async customer(uid: string): Promise<LoginUser | null> {
            try {
              const docRef = doc(collections.customer, uid); // UIDの型をstringに変更
              const docSnap = await getDoc(docRef);
              if (docSnap.exists()) {
                return docSnap.data() as LoginUser;
              } else {
                console.log('No such document!');
                return null;
              }
            } catch (error) {
              console.error('Error fetching customer data:', error);
              return null;
            }
          },
          async ga4Datas(uid:string){
            const q = query(collections.analyticsDatas, 
              where('uid','==',uid),
              orderBy('date', 'desc')
            );
            const querySnapshot = await getDocs(q);
            const datas = querySnapshot.docs.map(doc => doc.data());
            return datas;
          },
          async endUser(uid: string): Promise<EndUser[]> {
            console.log('start endUser',uid)
            const q = query(collections.endUsers, where('parentId', '==', uid));
            const querySnapshot = await getDocs(q);
            const endUsers: EndUser[] = [];
            querySnapshot.forEach((doc) => {
              endUsers.push(doc.data() as EndUser);
            });
            return endUsers;
          },
        },
        delete:{
          endUser(uid:string){
            const docRef = doc(collections.endUsers,uid);
            deleteDoc(docRef);
          }
        }
      }
  })

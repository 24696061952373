<template>
    <v-container>
        <v-row>
        <v-col cols="12" sm="6" offset-sm="3">
            <v-card>
            <v-card-title>
                <span class="headline">Login</span>
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="method.login(router)">
                <v-text-field
                    v-model="data.loginUser.email"
                    label="email"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="data.loginUser.password"
                    label="Password"
                    type="password"
                    required
                ></v-text-field>
                <v-btn
                    color="primary"
                    type="submit"
                >
                    Login
                </v-btn>
                </v-form>
            </v-card-text>
            </v-card>
        </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { data } from '../composables/useData';
import { method } from '../composables/useMethod';
import { useRouter } from 'vue-router'
const router = useRouter();


</script>
  
<template>
      <v-expansion-panels>
        <!-- 基本設定パネル -->
        <v-expansion-panel
          title="基本設定"
          color="info"
        >
          <v-expansion-panel-text>
            <v-form>
              {{ data.loginUser.name }}
              <v-text-field
                v-model="data.loginUser.name"
                label="名前"
                prepend-inner-icon="mdi-help-circle"
                @click:prepend-inner="showHint('名前')"
              ></v-text-field>
              <v-text-field
                v-model="data.loginUser.email"
                label="メールアドレス"
                prepend-inner-icon="mdi-help-circle"
                @click:prepend-inner="showHint('メールアドレス')"
              ></v-text-field>
              <v-btn color="primary" @click="changePassword" :disabled="data.disabled">パスワード変更</v-btn>
              <v-btn color="secondary" :disabled="data.disabled">保存する</v-btn>
            </v-form>
          </v-expansion-panel-text>
        </v-expansion-panel>
  
        <!-- 顧客設定パネル -->
        <v-expansion-panel
          title="顧客設定"
          color="info"
        >
        <v-expansion-panel-text>
            <v-btn color="primary" @click="method.endUser.create()" :disabled="data.disabled">新規顧客作成</v-btn>
            <v-text-field v-model="data.loginUser.endUser.name" placeholder="お名前"></v-text-field>
            <v-text-field v-model="data.loginUser.endUser.email" placeholder="メールアドレス"></v-text-field>
            <v-divider class="my-4"></v-divider>
            <v-btn color="secondary" @click="method.endUser.saveUseProperties()" :disabled="data.disabled">保存する</v-btn>
            <v-expansion-panels v-for="user in data.loginUser.endUser.list" :key="user.uid">
              <v-expansion-panel :title="user.name">
                <v-expansion-panel-text>
                  <v-btn color="primary" @click="data.dialog.endUserAnalytics = true">表示レポート設定</v-btn>
                  <v-btn color="primary" @click="data.dialog.endUserMailText = true">送信メール本文設定</v-btn>
                  <v-btn color="error" @click="method.endUser.delete(user.uid)" :disabled="data.disabled">アカウント削除</v-btn>
                </v-expansion-panel-text>
                <v-dialog
                  v-model="data.dialog.endUserAnalytics"
                  width="500"
                >
                <v-card>
                  <v-expansion-panels  v-for="(account) in data.loginUser.reports.accounts" :key="account.name">
                    <v-expansion-panel
                      v-if="account.properties"
                      :title="account.displayName"
                    >
                      <v-expansion-panel-text>
                        <v-card>
                        <v-card-text>
                          <v-checkbox v-for="property in account.properties"
                            :key="property.name"
                              v-model="user.useProperties"
                              :label="property.displayName"
                              :value="property"
                          ></v-checkbox>
                        </v-card-text>
                      </v-card>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
                </v-dialog>
                <v-dialog
                  v-model="data.dialog.endUserMailText"
                  width="500"
                >
                <v-card>
                  <v-card-text>
                    <v-text-field
                      v-model="user.mailText.subject"
                      label="タイトル"
                    ></v-text-field>
                    <v-textarea
                      v-model="user.mailText.firstHalf"
                      label="前半部"
                    ></v-textarea>
                    <v-textarea
                      v-model="user.mailText.secondHalf"
                      label="後半部"
                    ></v-textarea>
                    <v-textarea
                      v-model="user.mailText.signature"
                      label="署名"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="secondary" @click="method.endUser.save()">保存する</v-btn>
                  </v-card-actions>
                </v-card>
                </v-dialog>
              </v-expansion-panel>
            </v-expansion-panels>
            
          </v-expansion-panel-text>
        </v-expansion-panel>
  
        <!-- アナリティクス設定パネル -->
        <v-expansion-panel
          title="アナリティクス設定"
          color="info"
        >
          <v-expansion-panel-text>
            <v-btn color="primary" @click="method.google.getGoogleAnalyticsAccounts(),data.google.dialog = true">プロパティの再取得</v-btn>
            <v-btn color="secondary" @click="method.settings.save.properties">保存する</v-btn>  
            <v-expansion-panels  v-for="(account) in data.loginUser.reports.accounts" :key="account.name">
              <v-expansion-panel
                v-if="account.properties"
                :title="account.displayName"
              >
                <v-expansion-panel-text>
                  <v-card>
                  <v-card-text>
                    <v-checkbox v-for="property in account.properties"
                      :key="property.name"
                        v-model="data.loginUser.reports.selectProperties"
                        :label="property.displayName"
                        :value="property"
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
                </v-expansion-panel-text>
                
              </v-expansion-panel>
            </v-expansion-panels> 
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      
      <v-dialog
        v-model="data.dialog.analyticsPreference"
        width="500"
      >
        <v-card>
          <v-card-text>
            <div class="text-center overflow-hidden">
              <v-progress-circular color="primary" indeterminate :size="54" v-if="!data.loginUser.reports.accounts.length" ></v-progress-circular>
            </div>
            <GetAccessToken v-if="data.loginUser.reports.accounts.length"></GetAccessToken>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="data.google.dialog"
        width="500"
      >
        <v-card>
          <v-card-text>
            <div class="text-center overflow-hidden">
              <v-progress-circular color="primary" indeterminate :size="54" v-if="!data.loginUser.reports.accounts.length" ></v-progress-circular>
            </div>
            <GetAccessToken v-if="data.loginUser.reports.accounts.length"></GetAccessToken>
          </v-card-text>
        </v-card>
      </v-dialog>
  </template>
  

<script setup lang="ts">
import { ref, onMounted,computed } from 'vue';
import { data } from '../composables/useData';
import { method } from '../composables/useMethod';
import GetAccessToken from './GetAccessToken.vue';

const filterdProperties = computed(() => {
  return data.loginUser.reports.selectProperties.filter(item => data.loginUser.reports.properties.find(property => property.account === item.account));
});
  



</script>
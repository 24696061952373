<template>
    <BarChart :chartData="reportData" :options="data.reportPage.graph.options" />
  </template>

<script setup lang="ts">
import { data } from '../../composables/useData';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { computed } from 'vue';
import dayjs from 'dayjs';
Chart.register(...registerables);

const reportData = computed(() => {
  const report = data.loginUser.viewReports[data.loginUser.activeReport];

  // `regionData` を抽出
  const regionData = report.regionData;

  const labels = regionData.map(item => item.region);
  const activeUsers = regionData.map(item => item.activeUsers);
  const pageViews = regionData.map(item => item.pageViews);

  // チャートデータを整形する
  return {
    labels,
    datasets: [
      {
        label: 'アクティブユーザー数',
        data: activeUsers,
        backgroundColor: '#36A2EB',
      },
      {
        label: 'ページビュー数',
        data: pageViews,
        backgroundColor: '#FF6384',
      }
    ]
  };
});


</script>

<template>
  <v-tabs
     v-model="data.loginUser.activeComponentIndex"
     align-tabs="center"
     color="info"
     show-arrows
  >
    <v-tab v-for="(reportSheet,i) in data.loginUser.reportList" :key="i">
      {{ reportSheet.title }}
    </v-tab>
  </v-tabs>
  <v-tabs-window
     v-model="data.loginUser.activeComponentIndex"
     style="height: 700px"
     show-arrows
     >
    <v-tabs-window-item v-for="(reportSheet, i) in data.loginUser.reportList"
    :key="i">
        <component
          :is="getComponent(reportSheet.componentName)"
        ></component>
      </v-tabs-window-item>
      </v-tabs-window>
      
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';
import { data } from '../composables/useData';
import { method } from '../composables/useMethod';
import DayCounts from './ReportComponents/DayCounts.vue';
import DeviceCounts from './ReportComponents/DeviceCounts.vue';
import SearchEngineCounts from './ReportComponents/SearchEngineCounts.vue';
import PageData from './ReportComponents/PageData.vue';
import RegionData from './ReportComponents/RegionData.vue';
import SourceData from './ReportComponents/SourceData.vue';
import TopView from './ReportComponents/TopView.vue';
import { DefineComponent } from 'vue';

const componentsMap: { [key: string]: DefineComponent<object, object, any> } = {
  TopView,
  DayCounts,
  DeviceCounts,
  SearchEngineCounts,
  PageData,
  RegionData,
  SourceData,
};

const getComponent = (componentName: string): DefineComponent<object, object, any> | null => {
  const component = componentsMap[componentName];
  return componentsMap[componentName] || null;
};

</script>

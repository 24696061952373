<template>
    <v-container>
        <v-row justify="center">
            <v-col lg="6">
                <v-card>
                    <v-card-title>新規登録</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                            v-model="data.loginUser.name"
                                label="名前"
                                :rules="[v => !!v || '名前を入力してください']"
                            >
                            </v-text-field>
                            <v-text-field
                                v-model="data.loginUser.email"
                                :rules="[v => !!v || 'メールアドレスを入力してください', v => /.+@.+\..+/.test(v) || '有効なメールアドレスを入力してください']"
                            ></v-text-field>
                            <v-text-field
                                v-model="data.loginUser.password"
                                label="パスワード"
                                :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                @click:append-inner="show1 = !show1"
                                :rules="[v => v.length >= 6 || '最低6文字が必要です']"
                            ></v-text-field>
                        </v-form>                           
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                        @click="method.signUp.submit(router)"
                        :disabled="!(data.loginUser.name && data.loginUser.email && data.loginUser.password && data.loginUser.password.length >= 6) || data.overlay">登録する
                    </v-btn>
                        <v-btn
                        @click="method.login(router)"
                        >ログインする</v-btn>
                        
                        <v-btn @click="method.google.getGoogleAnalyticsAccounts()">アカウントリスト取得</v-btn>
                        <v-btn @click="method.google.getGoogleAnalyticsProperties()">プロパティリスト取得</v-btn>
                        <!-- <v-btn @click="method.google.getGAData(router,null)">GA4データ取得（テスト用）</v-btn> -->
                        <v-btn @click="method.google.getGAData(router,true)">GA4データ取得（テスト用 1年分）</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { data } from '../composables/useData'
import { method } from '../composables/useMethod'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
const router = useRouter();

const show1 = ref(false)

</script>
<template>
  <v-row>
    <v-col>
      activeReportId: {{ data.loginUser.activeReportId }}
    </v-col>
  </v-row>

</template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { data } from '../composables/useData';
  import { method } from '../composables/useMethod';
  
  export default defineComponent({
    name: 'HomePage',
    setup() {
      return { data, method };
    },
  });
  </script>
  
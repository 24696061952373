<template>
    <BarChart :chartData="pageReportData" :options="chartOptions" />
  </template>
  
  <script setup lang="ts">
  import { data } from '../../composables/useData';
  import { BarChart } from 'vue-chart-3';
  import { Chart, registerables } from 'chart.js';
  import { computed } from 'vue';
  import dayjs from 'dayjs';
  Chart.register(...registerables);
  
  const pageReportData = computed(() => {
    const report = data.loginUser.viewReports[data.loginUser.activeReport];
    const pageData = report.pageData;
  
    const labels = pageData.map(item => item.pagePath);
    const activeUsers = pageData.map(item => item.activeUsers);
    const pageViews = pageData.map(item => item.pageViews);
  
    return {
      labels,
      datasets: [
        {
          label: 'アクティブユーザー数',
          data: activeUsers,
          backgroundColor: '#36A2EB',
        },
        {
          label: 'ページビュー数',
          data: pageViews,
          backgroundColor: '#FF6384',
        }
      ]
    };
  });

  const chartOptions = {
  indexAxis: 'y', // グラフを水平にするためのオプション
};
  </script>
  
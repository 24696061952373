<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title></v-card-title>
                    <v-card-text></v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
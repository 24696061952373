<template>
  <v-app>
    <v-main>

        <v-navigation-drawer v-model="data.naviDrawer" app v-if="!route.meta.hideAppBar"
        >
          <!-- ナビゲーションメニューの内容 -->
          <v-list v-model:opend="data.list.open">
            <v-list-item prepend-icon="mdi-home" title="ホーム" @click="router.push('/')"></v-list-item>
            <v-list-item prepend-icon="mdi-home" title="ログイン" @click="router.push('/loginpage')"></v-list-item>
            <v-list-item prepend-icon="mdi-home" title="サインアップ" @click="router.push('/signuppage')"></v-list-item>
            <v-list-group value="GA4レポート">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  title="GA4レポート"
                  prepend-icon="mdi-account"
                >
                </v-list-item>
              </template>
              <v-list-item
                  v-for="(menu, index) in reportList"
                  :key="index"
                  :prepend-icon="menu.icon"
                  @click="method.menu.next(router, menu.accountId, index)"
                >
                  <v-list-item-title v-text="menu.accountName"></v-list-item-title>
                </v-list-item>
            </v-list-group>
            <v-list-item prepend-icon="mdi-home" title="設定" @click="router.push('/settingspage')"></v-list-item>
          </v-list>
        </v-navigation-drawer>
      <v-app-bar app v-if="!$route.meta.hideAppBar">
        <v-app-bar-nav-icon @click="data.naviDrawer = !data.naviDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ data.loginUser.activeReportData.title }}</v-toolbar-title>
      </v-app-bar>

      <!-- ページの主要なコンテンツ -->
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { computed,onMounted,ref } from 'vue';
import { data } from './composables/useData';
import { method } from './composables/useMethod';
import { useRoute,useRouter } from 'vue-router';

  const route = useRoute();
  const router = useRouter();

  const reportList = computed(() => data.loginUser.viewReports.filter((v1,i1,a1) => a1.findIndex(v2 => v1.accountName === v2.accountName) === i1));

</script>

import { reactive,nextTick } from 'vue';
import { data } from './useData';
import { firebase } from './useFirebase'
import { cloudFunctions } from './useCloudFunctions'
import dayjs from 'dayjs'

export  const method = reactive({
      async login (router:any) {
          console.log('login');
          const userCredential = await firebase.userLogin(data.loginUser.email,data.loginUser.password);
          if(userCredential && 'user' in userCredential){
          data.loginUser.uid = userCredential.user.uid;
          const customerData = await firebase.firestore.load.customer(data.loginUser.uid);
          const ga4Datas = await firebase.firestore.load.ga4Datas(data.loginUser.uid);
          const endUsers = await firebase.firestore.load.endUser(data.loginUser.uid);
          console.log('customerData',customerData,'ga4Datas',ga4Datas,'endUsers',endUsers)

          console.log('data.loginUser.endUser.list',data.loginUser.endUser.list)
          if (customerData) {
            data.loginUser = customerData;
            data.loginUser.endUser.list = endUsers;
          } else {
            console.error('Failed to load customer data');
            // 必要に応じてエラーハンドリングを行う
          }
          data.loginUser.viewReports = ga4Datas.map(this.transformData);
          data.loginUser.first = false;
          data.loginUser.viewReportSelected = []; //一時的な処置
          console.log('login',data.loginUser)
            router.push('/')
          }
      },
      transformData(data: any): AnalyticsReports {
        return {
          date: data.date,
          accountName: data.accountName,
          accountId: data.accountId,
          dateData: data.dateData.map((item: any) => ({
            activeUsers: item.activeUsers,
            screenPageViews: item.screenPageViews,
            date: item.date,
          })),
          deviceData: data.deviceData.map((item: any) => ({
            deviceCategory: item.deviceCategory,
            activeUsers: item.activeUsers,
            screenPageViews: item.screenPageViews,
            date:item.date
          })),
          regionData: data.regionData.map((item: any) => ({
            region: item.region,
            activeUsers: item.activeUsers,
            screenPageViews: item.screenPageViews,
            date:item.date
          })),
          pageData: data.pageData.map((item: any) => ({
            pagePath: item.pagePath,
            pageTitle: item.pageTitle,
            activeUsers: item.activeUsers,
            screenPageViews: item.screenPageViews,
            date:item.date
          })),
          sourceData: data.sourceData.map((item: any) => ({
            source: item.sessionManualSource,
            activeUsers: item.activeUsers, // キー名の変更
            screenPageViews: item.screenPageViews,
            date:item.date
          })),
          uid: data.uid,
          docId: data.docId,
          selected:[]
        };
      },
      setActive(route:any,item:any,index:number){
        console.log('setActive',route,index)
        if(route.path === '/ga4AccountListPage'){
          console.log('/ga4AccountListPage')
          data.active.accountIndex = index;
          console.log(data.active.accountIndex)
        }else if(route.path === '/ga4MonthListPage'){
          data.active.activeMonth = item.title;
          console.log(data.active.activeMonth);
        }
      },
      menuCarouselNext(router:any,menu:any,item:any,index2:number){
        console.log('menuCarouselNext',menu,item,index2)
        if(item.to){
          data.loginUser.activeReportData = item;
          console.log(data.loginUser.activeReportData)
          this.report.common.setData();
          router.push(item.to);
        }else{
          // if(item.title === 'GA4レポート'){
          //   data.menuList.push(data.loginUser.analyticsAccounts)
          // } else if(item.reportMonth){
          //   data.menuList.push(item.reportMonth)
          // }
          // nextTick(() => {
          //   data.listCarouselIndex++;
          // })
        }
      },
      menu:{
        next(router:any,docId:string,index:number){
          console.log('menu next', docId,index)
          data.loginUser.activeReportId = docId;
          // data.loginUser.activeReportList = menu;
          data.loginUser.activeReport = index;
          console.log('next',data.loginUser.activeReportList)
          router.push(data.loginUser.reports.to);
        }
      },
      // backMenu:{
      //   common(){
      //     // data.listCarouselIndex--;
      //     // data.menuList.pop();
      //   },
      //   general(router:any,route:any){
      //     console.log(router,route)
      //     if(route.path != '/'){
      //       router.go(-1);
      //     }
      //       this.common();
      //   },
      // },  
      handleNextClick(onClick:any){
        onClick();
      },
      report:{
        common:{
          getData(){
            console.log('get')
          },
          setData(){
            console.log('set')
            // data.loginUser.activeReportList = data.loginUser.analyticsAccounts.filter((item:any) => item.id === data.loginUser.activeReportData.accountId);
            console.log(data.loginUser.activeReportList)
          },
        },
          sum(){
            const selectedReportPorperty =  data.loginUser.viewReports.filter((item:any) => item.accountId === data.loginUser.activeReportId);
            return selectedReportPorperty.map((monthData:any) => {
            const activeUsers = monthData.dateData.reduce((acc:number, item:any) => acc + Number(item.activeUsers), 0);
            const screenPageViews = monthData.dateData.reduce((acc:number, item:any) => acc + Number(item.screenPageViews), 0);
            const date = dayjs(monthData.date).format('YYYY年M月');
            const docId = monthData.docId;
              return {
                date,
                activeUsers,
                screenPageViews,
                docId,
              }
            });
          },
          graphData(dateData:string){
            const selectedReportPorperty =  data.loginUser.viewReports.filter((item:any) => item.accountId === data.loginUser.activeReportId);
            return selectedReportPorperty.map((monthData:any,index:number) => {
              const sortedDateData = monthData[dateData].slice().sort((a:any, b:any) => dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1);
              // console.log('sortedDateData',sortedDateData)
              const labels = sortedDateData.map((item:any) => dayjs(item.date).format('D日'));
              const dateLabel = dayjs(monthData.date).format('YYYY年M月');
              const userData = sortedDateData.filter((item:any) => {
                return {
                activeUsers: item.activeUsers,
                screenPageViews: item.screenPageViews,
                }
              });
              // console.log('userData',userData)
              // チャートデータを整形する
              return {
                labels,
                docId: monthData.docId,
                datasets: 
                  {
                    label: dateLabel,
                    data: userData,
                    backgroundColor:data.reportPage.colorList[index],
                    fill: true,
                  }
                
              };
            });
          },
      },
      signUp:{
        async submit(router:any){
          console.log('submit')
          data.overlay = true;
          const result = await firebase.userRegister(data.loginUser.email,data.loginUser.password);
          if(result && 'user' in result){
              data.loginUser.uid = result.user.uid;
              console.log('singup submit', data.loginUser)
              data.loginUser.password = '';
              const createNewUser = await firebase.firestore.save.customer(data.loginUser);
              console.log('createNewUser',createNewUser)
              if(createNewUser){
                const result = await method.google.requestGoogleAuth();
                const authURL = result.data as { url:string }
                console.log('authURL',authURL)
                if(authURL.url){
                window.location.href = authURL.url;
                }
              }
          } else {
              console.log('登録エラー',result)
          }
      }
      },
      google:{
        requestGoogleAuth(){
          console.log('requestGoogleAuth')
          return cloudFunctions.googleAuth.request(data.loginUser);
        },
        async requestAccessToken(code:string){
          console.log('requestAccessToken')
          const requestAccessToken = cloudFunctions.googleAuth.requestAccessToken;
          const result = await  requestAccessToken(code);
          console.log('result',result)
          return result
        },
        async getGAData(router:any,use:boolean | null) {
          console.log('getGAData');
          data.overlay = true;
          const properties = data.loginUser.reports.selectProperties;
        
          const results = [];
        
          for (const item of properties) {
            const sendData = {
              name: item.name,
              first: use
            };
            const [dateData, deviceData, regionData, pageData, sourceData]:any = await Promise.all([
              cloudFunctions.googleAuth.getGAData(sendData),
              cloudFunctions.googleAuth.getDeviceReport(sendData),
              cloudFunctions.googleAuth.getRegionReport(sendData),
              cloudFunctions.googleAuth.getPageReport(sendData),
              cloudFunctions.googleAuth.getSourceReport(sendData)
            ]);
            console.log('dateData', dateData, 'deviceData', deviceData, 'regionData', regionData, 'pageData', pageData, 'sourceData', sourceData);
        
            const returnData = [];
            for (const ga4Item of dateData.data) {
              const date = dayjs(ga4Item.date).format('YYYY-MM');
              const check = await method.filter.analyticsRedundancy(date, item.name); //日付とプロパティIDが重複していないかチェック
              console.log('check', check);
              if (check) continue;
              returnData.push({
                accountName: item.displayName,
                accountId: item.name,
                ...(use !== null && { accountUse: use }),
                dateData: ga4Item,
                deviceData: deviceData.data.find((d: any) => d.date === ga4Item.date) || {},
                regionData: regionData.data.find((d: any) => d.date === ga4Item.date) || {},
                pageData: pageData.data.find((d: any) => d.date === ga4Item.date) || {},
                sourceData: sourceData.data.find((d: any) => d.date === ga4Item.date) || {},
                date: date,
                uid: data.loginUser.uid
              });
            }
            if (returnData.length > 0) {
              results.push(...returnData);
            }
          }
        
          try {
            console.log('All reports:', results);
            // await firebase.firestore.save.analyticsProperties(data.loginUser);
            if (results.length === 0) return;
            await firebase.firestore.save.ga4Datas(results);
            data.overlay = false;
            if (data.loginUser.first) {
              router.push('/');
            }
          } catch (error) {
            console.error('Error getting reports:', error);
            data.overlay = false; // Ensure the overlay is turned off in case of an error
          }
        
        },
        async getGoogleAnalyticsAccounts(){
          console.log('getGoogleAnalyticsAccounts')
          const result = await cloudFunctions.googleAuth.getGoogleAnalyticsAccounts(data.loginUser);
          console.log('result',result)
          data.loginUser.reports.accounts = (result.data as any[]).map((item:any) => {
            return {
              displayName: item.displayName,
              name:item.name,
            }
          })
          return result
        },
        async getGoogleAnalyticsProperties(){
          console.log('getGoogleAnalyticsProperties');
          data.overlay = true;
        
          // accountsのプロパティを取得
          const propertyLists = await Promise.all(data.loginUser.reports.accounts.map(async (account: AnalyticsAccount) => {
            const result = await cloudFunctions.googleAuth.getGoogleAnalyticsProperties(account);
            console.log('getGoogleAnalyticsProperties', result);
        
            if (result.data === null) {
              // data.overlay = false;
              data.loginUser.reports.getAccountName = '';
              return [];
            }
        
            // プロパティデータをアカウントに追加
            account.properties = (result.data as AnalyticsProperties[]).map((property: AnalyticsProperties) => property);
            return account.properties;
          }));
        
          // プロパティデータをログインユーザーのレポートに追加
          data.loginUser.reports.properties = propertyLists.flat();
          console.log('data.loginUser.reports.properties', data.loginUser.reports.properties);
          data.overlay = false;
          return propertyLists;
        },        
        async saveGoogleAnalyticsProperties(){
          await firebase.firestore.save.analyticsProperties(data.loginUser);
        },
    },
    settings: {
      save: {
        async properties() {
          console.log('save properties');
          const result = confirm('保存しますか？');
          if (result) {
            const analyticsMonth = method.filter.analyticsMonth();
            console.log('analyticsMonth', analyticsMonth);
            const newSelectProperties = data.loginUser.reports.selectProperties.filter((property: AnalyticsProperties) => {
              console.log('newSelectProperties',property.name)
              return !analyticsMonth.some((item) => item.accountId === property.name);
            });
            if(newSelectProperties.length === 0){return;}
            data.loginUser.reports.selectProperties = newSelectProperties;
            console.log('newSelectProperties', newSelectProperties);
            if(data.loginUser.uid){
              await method.google.getGAData(null,null);
              const ga4Datas = await firebase.firestore.load.ga4Datas(data.loginUser.uid);
              // 変更後のプロパティリストを保存
              data.loginUser.viewReports = ga4Datas.map(method.transformData);
              await firebase.firestore.save.analyticsProperties(data.loginUser);
            }
          }
        }
      }
    },
    endUser:{
      async create(){
        console.log('create')
        const confirmResult = confirm('ユーザーを作成しますか？');
        if(!confirmResult){return;}
        data.disabled = true;
        const result = await cloudFunctions.endUser.create(data.loginUser.endUser.email);
        console.log('create exit',result)
        if(result){
          try{
          const newEndUser = (result.data as EndUser);
          newEndUser.name = data.loginUser.endUser.name;
          newEndUser.parentId = data.loginUser.uid;
          newEndUser.useProperties = [];
          if(data.loginUser.endUser.list === undefined){data.loginUser.endUser.list = [];}
          data.loginUser.endUser.list.push(newEndUser);
          console.log('newEndUser',data.loginUser.endUser)
          await firebase.firestore.save.endUser(newEndUser);
          alert('ユーザーを作成しました。')
          data.loginUser.endUser.email = '';
          data.loginUser.endUser.name = '';
          } catch (error) {
            const data = result.data as {
              errorInfo: {
                code: string;
                message: string;
              };
              codePrefix: string;
              name: string;
              parentId: string;
            };
            const message =  data.errorInfo.message;
            alert( `エラーが発生しました。 ${message}`, )
          }
          data.disabled = false;
        }
      },
      async login(email:string,password:string){
        console.log('login')
        await cloudFunctions.endUser.login(email,password);
      },
      async load(){
        console.log('load')
        const endUsers:EndUser[] | null = await firebase.firestore.load.endUser(data.loginUser.uid as string);
        console.log('endUsers',endUsers)
        if(endUsers){
          data.loginUser.endUser.list = endUsers;
        }
      },
      async save(){
        console.log('save')
        const result = confirm('保存しますか？');
        if(result){
          const endUser = data.loginUser.endUser;
          if(endUser.uid){
            await firebase.firestore.save.endUser(endUser);
          }
        }
      },
      async saveUseProperties(){
        console.log('saveUseProperties')
        const result = confirm('保存しますか？');
        data.disabled = true;
        if(result){
          data.loginUser.endUser.list.forEach(async (endUser:EndUser) => {
          if(endUser.uid){
            await firebase.firestore.save.endUser(endUser);
          }
        });
        alert('保存しました')
        }
        data.disabled = false;
      },
      async delete(endUserUid:string){
        const result = confirm('アカウントを削除しますか？この操作は取り消し出来ません。');
        if(result){
          data.disabled = true;
          const endUsers = data.loginUser.endUser.list;
          const newEndUsers = endUsers.filter((item:EndUser) => item.uid !== endUserUid);
          data.loginUser.endUser.list = newEndUsers;
          await cloudFunctions.endUser.delete(endUserUid);
          const message = await firebase.firestore.delete.endUser(endUserUid);
          console.log('delete',message)
          data. disabled = false;
          alert('削除が完了しました');
        }
      }
    },
    filter:{
      analyticsMonth(){
        return data.loginUser.viewReports.map((item:AnalyticsReports) => {
          return {
            date: item.date,
            accountId: item.accountId,
          }
          //dateをチェックして、過去1年分のデータがあるかどうかもチェックする
        });
      },
      analyticsRedundancy(date:string,accountId:string){
        return data.loginUser.viewReports.some((item:AnalyticsReports) => {
          // console.log('analyticsRedundancy',item.date,date,item.accountId,accountId)
           return item.date === date && item.accountId === accountId;
        });
      }
    },
  })
<template>
    <v-container class="fill-height">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Googleアカウント連携</v-card-title>
            <v-card-text v-show="!data.loginUser.reports.getAccountName">
              <p>アクセス解析を希望するアカウントを選択してください。</p>
              <v-radio-group v-model="data.loginUser.reports.getAccountName" column>
                <v-radio
                  v-for="(item) in data.loginUser.reports.accounts"
                  :key="item.name"
                  :label="item.displayName + ' ' + item.name"
                  :value="item.name"
                ></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-card-text v-show="data.loginUser.reports.getAccountName">
                <v-list>
                    <v-list-item v-for="(item) in filteredProperties" :key="item.account">
                    <v-checkbox
                        v-model="data.loginUser.reports.selectProperties"
                        :label="item.displayName"
                        :value="item"
                    ></v-checkbox>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="data.loginUser.reports.getAccountName = ''" v-if="data.loginUser.reports.getAccountName">アカウント選択に戻る</v-btn>
              <v-btn @click="showDialog = true" :disabled="!data.loginUser.reports.selectProperties.length">変更を確認</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
  
      <v-dialog v-model="showDialog" max-width="500">
        <v-card>
          <v-card-title>変更内容の確認</v-card-title>
          <v-card-text>
            <p>選択されたプロパティ:</p>
            <ul>
              <li v-for="(item) in data.loginUser.reports.selectProperties" :key="item.name">{{ item.displayName }}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showDialog = false">キャンセル</v-btn>
            <v-btn @click="confirmChanges">確定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-overlay v-model="data.overlay" class="text-center">
        <v-progress-circular :width="7" :size="70" color="primary" indeterminate></v-progress-circular>
      </v-overlay>
    </v-container>
  </template>
  
  <script setup lang="ts">
  import { ref, onMounted,computed } from 'vue';
  import { method } from '../composables/useMethod';
  import { data } from '../composables/useData';
  import { useRouter } from 'vue-router';
  const router = useRouter();
  const showDialog = ref(false);
  
  onMounted(async () => {
    if(data.loginUser.first){ //初登録時のみの処理
      data.overlay = true;
      console.log('data.loginUser.uid', data.loginUser.uid);
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');
      const uid = url.searchParams.get('state');
      if(uid){ data.loginUser.uid = uid; }
      if(code){
        console.log(code);
        const result = await method.google.requestAccessToken(code);
        console.log('GetAccessToken.vue', result);
        const message = (result.data as { message: string }).message;
        if(message === 'success'){
          data.loginUser.googleTokens = true;
          const accountLists = await method.google.getGoogleAnalyticsAccounts();
          console.log('GetAccessToken getGoogleAnalyticsProperties', accountLists);
          const propertyLists = await method.google.getGoogleAnalyticsProperties();
          console.log('GetAccessToken getGoogleAnalyticsProperties', propertyLists);
        }
      }
      data.overlay = false;
    }
  });
  
  const confirmChanges = async () => {
    if(data.loginUser.first){
      await method.google.getGAData(router, data.loginUser.first);
    } else {
      await method.google.saveGoogleAnalyticsProperties();
    }
    showDialog.value = false;
  };

  const filteredProperties = computed(() => {
  return data.loginUser.reports.properties.filter(
    item => item.account === data.loginUser.reports.getAccountName
  );
});
</script>

  
  <style scoped>
.fill-height {
  height: 100%;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
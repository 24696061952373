import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue';
import Ga4ReportPage from '../views/Ga4ReportPage.vue';
import Ga4AccountListPage from '../views/Ga4AccountListPage.vue';
import SignupPage from '../views/SignupPage.vue';
import GetAccessToken from '@/views/GetAccessToken.vue';
import SettingsPage from '@/views/SettingsPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {sub: false},
  },
  {
    path: '/loginPage',
    name: 'LoginPage',
    component: LoginPage,
    meta: { hideAppBar: true,sub:false }
  },
  {
    path: '/ga4ReportPage',
    name: 'Ga4ReportPage',
    component: Ga4ReportPage,
    meta: {sub: true},
  },
  {
    path: '/ga4AccountListPage',
    name: 'Ga4AccountListPage',
    component: Ga4AccountListPage,
    meta: {sub: true},
  },
  {
    path: '/signupPage',
    name: 'SignupPage',
    component: SignupPage,
    // meta: { hideAppBar: true,sub:false }
  },
  {
    path: '/getAccessToken',
    name: 'GetAccessToken',
    component: GetAccessToken,
    meta: { hideAppBar: true,sub:false }
  },
  {
    path: '/settingspage',
    name: 'SettingsPage',
    component: SettingsPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
//   const isAuthenticated = /* ログイン状態をチェックするロジック */;
  
//   if (requiresAuth && !isAuthenticated) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;

import { app } from "./useFirebase";
import { getFunctions,httpsCallable,connectFunctionsEmulator } from 'firebase/functions';

const region = 'asia-northeast1'; //リージョン指定
const functions = getFunctions(app, region);
if(location.hostname === "localhost"){
  connectFunctionsEmulator(functions,"localhost",5000);
}

export const cloudFunctions = {
    googleAuth:{
        async request(loginUser:LoginUser){
            console.log('requestGoogleAuth')
            const requestGoogleAuth = httpsCallable(functions,'requestGoogleAuth');
            return requestGoogleAuth(loginUser);
        },
        requestAccessToken(code:string){
            console.log('requestAccessToken')
            const requestAccessToken = httpsCallable(functions,'requestAccessToken');
            return requestAccessToken({code});
        },
        getGAData(data:any){
            console.log('getGAData')
            const getGAData = httpsCallable(functions,'getGAData');
            return getGAData(data);
        },
        getGoogleAnalyticsAccounts(data:any){
            console.log('getGoogleAnalyticsAccounts')
            const getGoogleAnalyticsAccounts = httpsCallable(functions,'getGoogleAnalyticsAccounts');
            return getGoogleAnalyticsAccounts(data);
        },
        getGoogleAnalyticsProperties(data:any){
            console.log('getGoogleAnalyticsProperties')
            const getGoogleAnalyticsProperties = httpsCallable(functions,'getGoogleAnalyticsProperties');
            return getGoogleAnalyticsProperties(data);
        },
        getRegionReport(data:any){
            console.log('getRegionReport')
            const getRegionReport = httpsCallable(functions,'getRegionReport');
            return getRegionReport(data);
        },
        getPageReport(data:any){
            console.log('getPageReport')
            const getPageReport = httpsCallable(functions,'getPageReport');
            return getPageReport(data);
        },
        getSourceReport(data:any){
            console.log('getSourceReport')
            const getSourceReport = httpsCallable(functions,'getSourceReport');
            return getSourceReport(data);
        },
        getDeviceReport(data:any){
            console.log('getDeviceReport')
            const getDeviceReport = httpsCallable(functions,'getDeviceReport');
            return getDeviceReport(data);
        },
        
    },
    endUser:{
        async create(email:string){
        console.log('endUserCreate')
        const endUserCreate = httpsCallable(functions,'endUserCreate');
        return endUserCreate({email});
        },
        async login(email:string,password:string){
        console.log('endUserLogin')
        const endUserLogin = httpsCallable(functions,'endUserLogin');
        return endUserLogin({email,password});
        },
        async delete(uid:string){
        console.log('endUserDelete')
        const endUserDelete = httpsCallable(functions,'endUserDelete');
        return endUserDelete({uid});
        }
    },
    
}
<template>
  <BarChart :chartData="reportData" :options="data.reportPage.graph.options" />
</template>

<script setup lang="ts">
import { data } from '../../composables/useData';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { computed } from 'vue';
import dayjs from 'dayjs';
Chart.register(...registerables);

const reportData = computed(() => {

  const report = data.loginUser.viewReports[data.loginUser.activeReport];
  const sortedSourceData = report.sourceData;

  // 必要なソースのみを抽出
  const filteredSourceData = sortedSourceData.filter(item => item.source === 'google' || item.source === 'yahoo' || item.source === 'bing');

  const labels = filteredSourceData.map(item => item.source);
  const userData = filteredSourceData.map(item => item.activeUsers);
  const pageViewsData = filteredSourceData.map(item => item.pageViews);

  const backgroundColor = userData.map((_, i) => {
    if (i === 0) return '#36A2EB';
    if (i === 1) return '#FF6384';
    if (i === 2) return '#FFCE56';
  });

  // チャートデータを整形する
  return {
    labels,
    datasets: [
      {
        label: 'ユーザー数',
        data: userData,
        backgroundColor,
      },
      {
        label: 'ページビュー数',
        data: pageViewsData,
        backgroundColor,
      }
    ]
  };
});
</script>
